
import BaseToolbar from '@/components/core/BaseToolbar.vue';
import ReportingMixin from '@/mixins/reporting-mixin';
import { Component } from 'vue-property-decorator';

  @Component({
    mixins: [ReportingMixin],
    components: {
      BaseToolbar
    }
  })
export default class ReportingToolbar extends BaseToolbar {
    searchText!: string;
    teacherSearchText!: string;
    lessonListSearchText!: string;
    teacherId!: number;
    showStandardLessons!: boolean;
    showStandardTeachers!: boolean;

    get localSearchText() {
      if (this.showStandardTeachers) {
        return this.teacherSearchText;
      } else if (!this.showStandardLessons) {
        return this.searchText;
      } else {
        return this.lessonListSearchText;
      }
    }

    set localSearchText(value: string) {
      if (this.showStandardTeachers) {
        this.teacherSearchText = value;
      } else if (!this.showStandardLessons) {
        this.searchText = value;
      } else {
        this.lessonListSearchText = value;
      }
    }

    get localReportingComponent(): any {
      return this.currentPageInstance || {};
    }

    get back() {
      return this.localReportingComponent.back || this.emptyFunction;
    }

    get doExportPDF() {
      return this.localReportingComponent.doExportPDF || this.emptyFunction;
    }

    get doExportCSV() {
      return this.localReportingComponent.doExportCSV || this.emptyFunction;
    }
}
